import './App.css';
import About from './containers/about/About';
import Bannar from './containers/bannar/Bannar';
import Contact from './containers/contact-us/Contact';
import Figures from './containers/figures-section/Figures';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import JoinSection from './containers/join-section/JoinSection';
import NewSection from './containers/news-section/NewSection';
import OurSection from './containers/our-section/OurSection';
import Scrolls from './components/scrolls/Scrolls';
import Slider from './components/slider/Slider';
import Solution from './containers/solution-section/Solution';
import Wrapper from './components/wrapper/Wrapper';

function App() {
  return (
    <div className="App">
      <div className="page-wrapper">
        <Header/>
        <div className="page-content-wrapper">
          <div className="homepage-content-wrapper">
            <Scrolls/>
            <div className="home-content">
                <Bannar/>
                <About/>
                <OurSection/>
                <Figures/>
                <Solution/>
                <NewSection/>
                <JoinSection/>
                <Contact/>
            </div>
          </div>
        </div>
        <Footer/>
        <Slider/>
        <Wrapper/>
      </div>
    </div>
  );
}

export default App;
