import React from 'react'
import './slider.css'
export default function Slider() {
  return (
    <div className="sidebar-menu">
		{/* <div className="sidebar-inner">
			<div className="social-links">
				<a href="https://t.me/softpro_uz" target="_balnk"><i className="fa fa-telegram" aria-hidden="true"></i></a>
				<a href="https://www.youtube.com/" target="_balnk"><i className="fa fa-youtube" aria-hidden="true"></i></a>
				<a href="https://github.com/" target="_balnk"><i className="fa fa-github" aria-hidden="true"></i></a>
				<a href="https://instagram.com" target="_balnk"><i className="fa fa-instagram" aria-hidden="true"></i></a>
			</div>

		</div> */}
	</div>
  )
}
