import React from "react";
import "./figures.css";
import { useTranslation } from 'react-i18next';

export default function Figures() {
  const { t } = useTranslation();

  return (
    <div className="key-figures-section content-item" id="tab4">
      <div className="container">
        <div className="key-figures-inner">
          <div className="figures">
            <div>
              <div className="figure item0">
                <div className="figure-wrap">
                  <div className="count-wrap">
                    <span className="count">13</span>
                  </div>
                  <div className="text">{t('figures.total_projects')}</div>
                </div>
              </div>
              <div className="figure item1">
                <div className="figure-wrap">
                  <div className="count-wrap">
                    <span className="count">7</span>
                  </div>
                  <div className="text">{t('figures.websites')}</div>
                </div>
              </div>
            </div>
            <div>
              <div className="figure item2">
                <div className="figure-wrap">
                  <div className="count-wrap">
                    <span className="count">2</span>
                  </div>
                  <div className="text">{t('figures.mobile_apps')}</div>
                </div>
              </div>
              <div className="figure item3">
                <div className="figure-wrap">
                  <div className="count-wrap">
                    <span className="count">2</span>
                  </div>
                  <div className="text">{t('figures.desktop_apps')}</div>
                </div>
              </div>
              <div className="figure item4">
                <div className="figure-wrap">
                  <div className="count-wrap">
                    <span className="count">2</span>
                  </div>
                  <div className="text">{t('figures.telegram_bots')}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="key-text">
            <h2>{t('figures.completed_projects')}</h2>
          </div>
        </div>
      </div>
    </div>
  );
}
