import React from 'react'
import styles from './modal.module.css'

const formla = ({children, modal, setModal}) => {
  const stylesModal = [styles.modal]
  if(modal){
    stylesModal.push(styles.active)
  }
  return (
    <div className={stylesModal.join(' ')} onClick={()=> setModal(false)}>
        <div className={styles.modalContent} onClick={(e)=> e.stopPropagation()}>
            {children}
        </div>
    </div>
  )
}
export default formla
