import React from "react";
import "./footer.css";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="footer-inner">
        <div className="container">
          <div className="footer-top">
            <div className="footer-item">
              <div className="logo-footer">
                <a className="footer-main-logo" href="#">
                  <img src="assets/images/logos-nav.png" alt="SOFTPRO" />
                </a>
                <a className="footer-accessblity-logo" href="#">
                  <img src="assets/images/logos-nav2.png" alt="SOFTPRO" />
                </a>
              </div>
            </div>
            <div className="footer-item">
              <div className="contact">{/* Your contact content */}</div>
            </div>
            <div className="footer-item">
              <div className="social-links">
                <h3>{t("social-links.title")}</h3>
                <a href="https://t.me/softpro_uz" target="_blank">
                  <i className="fa fa-telegram" aria-hidden="true"></i>
                </a>
                <a href="https://www.youtube.com/" target="_blank">
                  <i className="fa fa-youtube" aria-hidden="true"></i>
                </a>
                <a href="https://github.com/" target="_blank">
                  <i className="fa fa-github" aria-hidden="true"></i>
                </a>
                <a href="https://instagram.com" target="_blank">
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="footer-bottom-inner">
              <div className="left-content">
                <p>
                  {t("footer-bottom.copyright", { year: 2021 })}
                  <span>{t("footer-bottom.company")}</span>{" "}
                  <a href="#" target="_blank">
                    {t("footer-bottom.all-rights-reserved")}
                  </a>{" "}
                </p>
              </div>
              <div className="right-links">
                <a href="#">{t("footer-bottom.company-name")} - </a>
                <a
                  target="_blank"
                  href="assets/Guvohnoma.pdf"
                  style={{ margin: 0 }}
                >
                  {t("footer-bottom.certificate")}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="back-to-top-ouuter">
          <div className="back-to-top">
            <p>
              {t("back-to-top")}
              <span></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
