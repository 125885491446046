import React from "react";
import "./contact.css";
import { useTranslation } from 'react-i18next';

export default function Contact() {
  const { t } = useTranslation();

  return (
    <div className="contact-us-section content-item" id="tab8">
      <div className="container">
        <div className="contact-us-inner">
          <h2>{t('contact.title')}</h2>
          <div className="icon">
            <img
              alt="icon contact"
              src="assets/images/icon-contact.png"
              className="img-fluid"
            />
          </div>
          <div className="text">
            <p>
              <span>{t('contact.phone')}: +998 99 896-83-73, +998 99 489-76-16 </span>
              <br />
              <br />
              <span>{t('contact.email')}: <a href="mailto:admin@softpro.uz">admin@softpro.uz</a></span>
            </p>
            <div className="button2">
              <a href="#">{t('contact.contact_us')}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}