import React from "react";
import "./wrapper.css";
import { useTranslation } from 'react-i18next';

export default function Wrapper() {
  const { t } = useTranslation();

  return (
    <div className="wprm-wrapper">
      <input id="toggle" type="checkbox"></input>
      <label htmlFor="toggle" className="hamburger">
        <div className="top-bun"></div>
        <div className="meat"></div>
        <div className="bottom-bun"></div>
      </label>
      <div className="nav">
        <div className="nav-wrapper">
          <nav>
            <a href="#tab1">{t('wrapper.home')}</a>
            <br></br>
            <a href="#tab2">{t('wrapper.about_us')}</a>
            <br></br>
            <a href="#tab3">{t('wrapper.services')}</a>
            <br></br>
            <a href="#tab5">{t('wrapper.portfolio')}</a>
            <br></br>
            <a href="#tab8">{t('wrapper.contact')}</a>
          </nav>
        </div>
      </div>
    </div>
  );
}
