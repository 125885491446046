import React, { useState } from "react";
import "./bannar.css";
import Modal from "../../components/modal/Modal";
import emailjs from "emailjs-com";
import Input from "../../components/UI/input/Input";
import { useTranslation } from 'react-i18next';

const Bannar = () => {
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);
  const [toSend, setToSend] = useState({
    from_name: "",
    email: "",
    title: "",
    message: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_sw9k868",
        "template_dp433k3",
        e.target,
        "PZmdEkuyxiut2T_qb"
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        alert(t('bannar.success_message'));
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
    e.target.reset();
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div className="banner-section content-item" id="tab1">
      <Modal modal={modal} setModal={setModal}>
        <form onSubmit={onSubmit}>
          <span className="text-center">{t('bannar.send_message')}</span>
          <div className="input-container">
            <Input
              type="text"
              name="email"
              value={toSend.email}
              onChange={handleChange}
            />
            <label>{t('bannar.email')}</label>
          </div>
          <div className="input-container">
            <Input
              type="text"
              name="to_name"
              value={toSend.from_name}
              onChange={handleChange}
            />
            <label>{t('bannar.name')}</label>
          </div>
          <div className="input-container">
            <Input
              type="text"
              name="from_name"
              value={toSend.title}
              onChange={handleChange}
            />
            <label>{t('bannar.message_subject')}</label>
          </div>
          <div className="input-container">
            <Input
              type="text"
              name="message"
              value={toSend.message}
              onChange={handleChange}
            />
            <label>{t('bannar.message')}</label>
          </div>
          <button
            onClick={() => {
              if (!toSend.from_name || !toSend.message || !toSend.email) {
                alert(t('bannar.invalid_message'));
                return;
              }
              alert(t('bannar.success_message_alert'));
              setModal(false);
            }}
            type="submit"
            className="btn"
          >
            {t('bannar.send')}
          </button>
        </form>
      </Modal>
      <div className="container">
        <div className="banner-inner">
          <div className="left-text">
            <h1>
              {t('bannar.company')} <span>{t('bannar.company_name')}</span>
            </h1>
            <div className="button-wrapper">
              <p>
                <span>
                  {t('bannar.company_description')}
                </span>
              </p>
              <div className="button-outer">
                <div className="button1">
                  <a href="https://t.me/softpro_uz">{t('bannar.telegram_contact')}</a>
                </div>
                <div className="button2">
                  <a onClick={() => setModal(true)}>{t('bannar.send_message')}</a>
                </div>
              </div>
            </div>
          </div>
          <div className="right-image-video">
            <div className="right-image-inner">
              <div className="video-image">
                <div className="image">
                  <svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="300.000000pt"
                    height="300.000000pt"
                    viewBox="0 0 300.000000 300.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
                      fill="#000000"
                      stroke="none"
                    ></g>
                  </svg>
                  <div className="video">
                    <video
                      controls
                      muted
                      autoPlay={"autoplay"}
                      preload="auto"
                      loop
                    >
                      <source
                        src="assets/code-background.mp4"
                        type="video/mp4"
                      />
                    </video>
                    <video
                      controls
                      muted
                      autoPlay={"autoplay"}
                      preload="auto"
                      loop
                    >
                      <source
                        src="https://www.nomadic-labs.com/wp-content/uploads/2021/05/big_buck_bunny_720p_2mb.mp4"
                        type="mp4/video"
                      />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bannar;
