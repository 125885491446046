// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en.json";
import translationUZ from "./locales/uz.json";
import translationRU from "./locales/ru.json";

// the translations
const resources = {
  en: translationEN,
  ru: translationRU,
  uz: translationUZ,
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "uz", // Default language fallback
    debug: false, // Set to false in production
    resources,
    interpolation: {
      escapeValue: false, // React already escapes the values
    },
  });

export default i18n;
