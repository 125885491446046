import React from "react";
import "./scrolls.css";
export default function Scrolls() {
  return (
    <div className="anochors-home-scroll-wrap">
      <div className="container-fluid">
        <div className="home-anchors">
          <div className="anchor-item tab1" data-tab="tab1">
            <span>a</span>
          </div>
          <div className="anchor-item tab2" data-tab="tab2">
            <span>a</span>
          </div>
          <div className="anchor-item tab3" data-tab="tab3">
            <span>a</span>
          </div>
          <div className="anchor-item tab4" data-tab="tab4">
            <span>a</span>
          </div>
          <div className="anchor-item tab5" data-tab="tab5">
            <span>a</span>
          </div>
          <div className="anchor-item tab6" data-tab="tab6">
            <span>a</span>
          </div>
          <div className="anchor-item tab7" data-tab="tab7">
            <span>a</span>
          </div>
          <div className="anchor-item tab8" data-tab="tab8">
            <span>a</span>
          </div>
        </div>
        {/* <div className="scroll">
          <div className="scroll-inner">
            <span>pastga</span>
          </div>
        </div> */}
      </div>
    </div>
  );
}
