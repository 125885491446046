import React from "react";
import "./ours.css";
import { useTranslation } from 'react-i18next';

export default function OurSection() {
  const { t } = useTranslation();

  return (
    <div className="our-values-section content-item" id="tab3">
      <div className="container">
        <div className="our-values-inner">
          <h2>{t('ours.title')}</h2>
          <div className="our-value-items">
            <div className="our-value-item">
              <div className="our-value-inner">
                <div className="icon">
                  <img
                    alt={t('ours.service1.icon-alt')}
                    src="assets/images/Proof-of-Stake.png"
                    className="img-fluid"
                  />
                </div>
                <div className="text">
                  <h3>{t('ours.service1.title')}</h3>
                  <p>
                    <span>
                      {t('ours.service1.description')}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="our-value-item">
              <div className="our-value-inner">
                <div className="icon">
                  <img
                    alt={t('ours.service2.icon-alt')}
                    src="assets/images/Security.png"
                    className="img-fluid"
                  />
                </div>
                <div className="text">
                  <h3>{t('ours.service2.title')}</h3>
                  <p>
                    <span>
                      {t('ours.service2.description')}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="our-value-item">
              <div className="our-value-inner">
                <div className="icon">
                  <img
                    alt={t('ours.service3.icon-alt')}
                    src="assets/images/icon-Commitment.png"
                    className="img-fluid"
                  />
                </div>
                <div className="text">
                  <h3>{t('ours.service3.title')}</h3>
                  <p>
                    <span>
                      {t('ours.service3.description')}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}