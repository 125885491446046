import React from "react";
import "./navbar.css";
import { useTranslation } from "react-i18next";

export default function Navbar() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="header">
      <div className="header-top">
        <div className="container-fluid">
          <div className="accessblity-module">
            {/* Language dropdown */}
            <div className="language-dropdown">
              <select
                onChange={(e) => changeLanguage(e.target.value)}
                value={i18n.language || "ru"}
              >
                <option value="uz">O'zbekcha</option>
                <option value="ru">Русский</option>
                <option value="en">English</option>
              </select>
            </div>

            <div className="front-resizer">
              <div className="increase">
                <span>+</span>A
              </div>
              <div className="reset">A</div>
              <div className="decrease">
                <span>-</span>A
              </div>
            </div>
            <div className="contrast">
              <div className="contrast-switcher">
                <div className="contrast-image">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="light"
                    width="18.633"
                    height="18.633"
                    viewBox="0 0 18.633   18.633"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="Rectangle_238"
                          data-name="Rectangle 238"
                          width="18.633"
                          height="18.633"
                          fill="currentColor"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="Groupe_1343"
                      data-name="Groupe 1343"
                      transform="translate(0 2)"
                    >
                      <g
                        id="Groupe_565"
                        data-name="Groupe 565"
                        transform="translate(0 -2)"
                        clipPath="url(#clip-path)"
                      >
                        <path
                          id="Tracé_408"
                          data-name="Tracé 408"
                          d="M75.45,2.15V0H72.8V2.145a7.371,7.371,0,0,1,1.312-.122,6.618,6.618,0,0,1,1.337.126"
                          transform="translate(-64.765)"
                          fill="currentColor"
                        />
                        <path
                          id="Tracé_409"
                          data-name="Tracé 409"
                          d="M72.8,148.4v2.252H75.45V148.4a7.136,7.136,0,0,1-2.649,0"
                          transform="translate(-64.765 -132.018)"
                          fill="currentColor"
                        />
                        <path
                          id="Tracé_410"
                          data-name="Tracé 410"
                          d="M20.723,17.385l-1.549-1.549L17.3,17.709l1.551,1.551a7.286,7.286,0,0,1,1.872-1.875"
                          transform="translate(-15.391 -14.088)"
                          fill="currentColor"
                        />
                        <path
                          id="Tracé_411"
                          data-name="Tracé 411"
                          d="M2.2,74.059A6.784,6.784,0,0,1,2.307,72.8H0V75.45H2.331A7.875,7.875,0,0,1,2.2,74.059"
                          transform="translate(0 -64.765)"
                          fill="currentColor"
                        />
                        <path
                          id="Tracé_412"
                          data-name="Tracé 412"
                          d="M124.4,19.275l1.566-1.566-1.874-1.873-1.56,1.56a7.279,7.279,0,0,1,1.867,1.879"
                          transform="translate(-109.006 -14.088)"
                          fill="currentColor"
                        />
                        <path
                          id="Tracé_413"
                          data-name="Tracé 413"
                          d="M122.3,122.72l1.585,1.585,1.874-1.873-1.584-1.584a7.29,7.29,0,0,1-1.875,1.872"
                          transform="translate(-108.801 -107.509)"
                          fill="currentColor"
                        />
                        <path
                          id="Tracé_414"
                          data-name="Tracé 414"
                          d="M149.61,72.8a6.782,6.782,0,0,1,.112,1.258,7.875,7.875,0,0,1-.136,1.391h2.121V72.8Z"
                          transform="translate(-133.074 -64.765)"
                          fill="currentColor"
                        />
                        <path
                          id="Tracé_415"
                          data-name="Tracé 415"
                          d="M18.87,120.98,17.3,122.549l1.873,1.873,1.575-1.575a7.285,7.285,0,0,1-1.879-1.867"
                          transform="translate(-15.391 -107.626)"
                          fill="currentColor"
                        />
                        <path
                          id="Tracé_416"
                          data-name="Tracé 416"
                          d="M40.638,33.539a5.548,5.548,0,1,0,5.548,5.548,5.554,5.554,0,0,0-5.548-5.548m-.068,9.456h-.081V35.18c.088,0,.075.044.072-.044a4.023,4.023,0,0,1,3.943,3.93,3.954,3.954,0,0,1-3.934,3.93"
                          transform="translate(50.059 48.337) rotate(180)"
                          fill="currentColor"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-bottom">
        <div className="container-fluid">
          <div className="header-inner flex-wrapper">
            <div className="site-logo">
              <a
                className="main-logo"
                href="index.html"
                title="Nomadic Labs"
                rel="home"
              >
                <span>
                  <img
                    id="site-logo"
                    src="assets/images/logos-nav.png"
                    alt="nomadic-labs"
                    className="img-responsive"
                  />
                </span>
              </a>
              <a
                className="accessblity-logo"
                href="index.html"
                title="Nomadic Labs"
                rel="home"
              >
                <span>
                  <img
                    id="site-logo"
                    src="assets/images/logos-nav2.png"
                    alt="nomadic-labs"
                    className="img-responsive"
                  />
                </span>
              </a>
            </div>
            <div className="header-right">
              <div className="header-inner-right">
                <div className="main-navigation">
                  <div className="menu-header-menu-container">
                    <ul id="menu-header-menu" className="menu">
                      {/* Navbar links with translations */}
                      <li
                        id="menu-item-9"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9"
                      >
                        <a href="#tab1">{t("navbar.home")}</a>
                      </li>
                      <li
                        id="menu-item-11"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11"
                      >
                        <a href="#tab2">{t("navbar.about_us")}</a>
                      </li>
                      <li
                        id="menu-item-219"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-219"
                      >
                        <a href="#tab3">{t("navbar.services")}</a>
                      </li>
                      <li
                        id="menu-item-220"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-220"
                      >
                        <a href="#tab4">{t("navbar.portfolio")}</a>
                      </li>
                      <li
                        id="menu-item-221"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-221"
                      >
                        <a href="#tab8">{t("navbar.contact")}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
