import React from "react";
import "./solution.css";
import { useTranslation } from 'react-i18next';

export default function Solution() {
  const { t } = useTranslation();

  return (
    <div className="industry-solution-section content-item" id="tab5">
      <div className="container">
        <div className="title-link">
          <h2>{t('solution.title')}</h2>
          {/* <div className="button2">
            <a href="#">🔗 {t('solution.learn_with_prices')}</a>
          </div> */}
        </div>
        <div className="swiper-content">
          <div className="swiper-container is-swiper">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="image-text">
                  <div className="left-text">
                    <img
                      alt={t('solution.project1.alt-text')}
                      src="assets/images/kasaba.png"
                      className="img-fluid"
                    />
                  </div>
                  <div className="right-text">
                    <h3>{t('solution.project1.title')}</h3>
                    <p>
                      {t('solution.project1.description')}
                    </p>
                    <div className="button2">
                      <a href="#">{t('solution.details')}</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="image-text">
                  <div className="left-text">
                    <img
                      alt={t('solution.project2.alt-text')}
                      src="assets/images/attendance1.png"
                      className="img-fluid"
                    />
                  </div>
                  <div className="right-text">
                    <h3>{t('solution.project2.title')}</h3>
                    <p>
                      {t('solution.project2.description')}
                    </p>
                    <div className="button2">
                      <a href="#">{t('solution.details')}</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="image-text">
                  <div className="left-text">
                    <img
                      alt={t('solution.project3.alt-text')}
                      src="assets/images/xavfsizmehnat.png"
                      className="img-fluid"
                    />
                  </div>
                  <div className="right-text">
                    <h3>{t('solution.project3.title')}</h3>
                    <p>
                      {t('solution.project3.description')}
                    </p>
                    <div className="button2">
                      <a href="#">{t('solution.details')}</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="image-text">
                  <div className="left-text">
                    <img
                      alt={t('solution.project4.alt-text')}
                      src="assets/images/kasaba2.png"
                      className="img-fluid"
                    />
                  </div>
                  <div className="right-text">
                    <h3>{t('solution.project4.title')}</h3>
                    <p>
                      {t('solution.project4.description')}
                    </p>
                    <div className="button2">
                      <a href="#">{t('solution.details')}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-pagination is-pagination"></div>
          <div className="swiper-pagination-outer">
            <div className="is-prev swiper-button-prev"></div>
            <div className="is-next swiper-button-next"></div>
          </div>
        </div>
      </div>
    </div>
  );
}