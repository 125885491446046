import React from "react";
import "./newsection.css";
import { useTranslation } from "react-i18next";

export default function NewSection() {
  const { t } = useTranslation();

  return (
    <div className="news-section content-item" id="tab6">
      <div className="container">
        <div className="news-inner">
          <h2>{t("new-section.title")}</h2>
          <div className="news-items">
            <div className="news-item">
              <div className="news-item-inner">
                <a href="#"></a>
                <div className="category">{t("new-section.director")}</div>
                <div className="title">
                  <h3>N.Z</h3>
                </div>
                <div className="item-content">
                  <div className="image">
                    <img
                      src="assets/images/director.png"
                      className="img-fluid"
                    />
                  </div>{" "}
                </div>
              </div>
            </div>

            <div className="news-item">
              <div className="news-item-inner">
                <a href="#"></a>
                <div className="category">
                  {t("new-section.senior-developer")}
                </div>
                <div className="title">
                  <h3>S.E</h3>
                </div>
                <div className="item-content">
                  <div className="image">
                    <img
                      src="assets/images/developer.png"
                      className="img-fluid"
                    />
                  </div>{" "}
                </div>
              </div>
            </div>

            <div className="news-item">
              <div className="news-item-inner">
                <a href="#"></a>
                <div className="category">
                  {t("new-section.middle-developer")}
                </div>
                <div className="title">
                  <h3>E.S</h3>
                </div>
                <div className="item-content">
                  <div className="image">
                    <img
                      src="assets/images/developer.png"
                      className="img-fluid"
                    />
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-link"></div>
        </div>
      </div>
    </div>
  );
}
