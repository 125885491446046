import React from "react";
import "./about.css";
import { useTranslation } from "react-i18next";

export default function About() {
  const { t } = useTranslation();

  return (
    <div className="what-wedo-section content-item" id="tab2">
      <div className="container">
        <div className="image-text">
          <div className="left-image">
            <img
              className="img-fluid logo-banner"
              src="assets/images/logo-banner.png"
              alt="SOFTPRO"
            />
            <img
              className="img-fluid logo-banner2"
              src="assets/images/logo-banner2.png"
              alt="SOFTPRO"
            />
          </div>
          <div className="right-text">
            <h2>{t("about.title")}</h2>
            <p>
              <span>{t("about.description")}</span>
            </p>

            <div className="button2">
              <a href="#tab8">{t("about.learn_more")}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
