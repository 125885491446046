import React from "react";
import "./joinsection.css";
import { useTranslation } from "react-i18next";

export default function JoinSection() {
  const { t } = useTranslation();

  return (
    <div className="join-us-section content-item" id="tab7">
      <div className="container">
        <div className="join-us-inner">
          <div className="round-image-text">
            <div className="round-image left-image">
              <div className="round-image-inner">
                <img
                  alt={t("join-section.alt-text")}
                  src="assets/images/image-cercle1.png"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="right-text">
              <h2>{t("join-section.title")}</h2>
              <p>
                <span>{t("join-section.goal")}</span>
              </p>
              <div className="button2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
