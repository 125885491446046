import React from 'react'
import Navbar from '../navbar/Navbar'

export default function Header() {
  return (
    <div className="header-wrapper">
          <Navbar/>
        </div>
  )
}
